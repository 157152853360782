<template>
  <b-modal ref="confirmModal" size="lg" hide-footer title="Hantera rättigheter">

    <b-row>
        <b-col>
            <p>{{$t('USER.PAGES_ACCESS_INFO')}}</p>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
          <b-button class="" variant="success" block @click="onGrantReadAll">{{$t('USER.ADD_READ_ALL')}}</b-button>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
          <b-button class="" variant="success" block @click="onGrantWriteAll">{{$t('USER.ADD_WRITE_ALL')}}</b-button>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
          <b-button class="" variant="danger" block @click="onRemoveReadAll">{{$t('USER.REMOVE_READ_ALL')}}</b-button>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
          <b-button class="" variant="danger" block @click="onRemoveWriteAll">{{$t('USER.REMOVE_WRITE_ALL')}}</b-button>
        </b-col>
    </b-row>

  </b-modal>
</template>

<style lang="scss" scoped>
</style>
<script>
import swal from 'sweetalert2';
import axios from 'axios';
import { read } from 'fs';

export default {
  name: 'page-action-modal',
  data() {
    return {
      p: false,
      a: false
    };
  },
  props: ['page'],
  async mounted() {},
  methods: {
    onGrantReadAll() {
      this.putPermissions('read');
    },
    onGrantWriteAll() {
      this.putPermissions('write');
    },
    onRemoveReadAll() {
      this.deletePermissions('read');
    },
    onRemoveWriteAll() {
      this.deletePermissions('write');
    },

    deletePermissions(action) {
      axios 
        .delete(`/page/users/${this.page.id}/${action}`, {  })
        .then(res => {
          if (res.status === 204) {
            swal.fire(this.$t('COMMON.OK'), this.$t('COMMON.GENERAL_SUCCESS'), 'success');
          }
          else {
            swal.fire(this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'), 'error');
          }
          this.$refs['confirmModal'].hide();
        })
        .catch(err => {
          console.error(err);
          swal.fire(this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'), 'error');
          this.$refs['confirmModal'].hide();
        });
    },

    putPermissions(action) {
      
      axios 
        .put(`/page/users/${this.page.id}/${action}`, {  })
        .then(res => {
          if (res.status === 204) {
            swal.fire(this.$t('COMMON.OK'), this.$t('COMMON.GENERAL_SUCCESS'), 'success');
          }
          else {
            swal.fire(this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'), 'error');
          }
          this.$refs['confirmModal'].hide();
        })
        .catch(err => {
          console.error(err);
          swal.fire(this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'), 'error');
          this.$refs['confirmModal'].hide();
        });
    },

    show() {
      this.$refs['confirmModal'].show();
    },
    
  },
  computed: {
    
  }
};
</script>
